// Created By: Chris
// Created On: 2023/06/17

// React
import React, {useRef} from 'react';

// Styles
import './About.scss';

// Images
import whoWeAreBanner from "../../assets/WhoWeAreBanner.jpg";
import people from "../../assets/colleagues-at-business-meeting-in-conference-room.jpg";
import people_mobile from "../../assets/_mobile/colleagues-at-business-meeting-in-conference-room.jpg";
import whatWeDo from "../../assets/What we do img.png";
import whatWeDo_mobile from "../../assets/_mobile/What we do img.png";
import whyUse from "../../assets/Why use DFM img.png";
import whyUse_mobile from "../../assets/_mobile/Why use DFM img.png";
import platforms from "../../assets/Platforms img.png";
import platforms_mobile from "../../assets/_mobile/Platforms img.png";
import glacier from "../../assets/glacier.16014a7e.png";
import glacier_mobile from "../../assets/_mobile/glacier.16014a7e.png";
import momentum from "../../assets/momentum.7fae1ab4.png";
import global from "../../assets/Global Icon.png";

// Framer
import {AnimatePresence, motion, useScroll, useTransform} from 'framer-motion';

// Constants
type tCard = {
    img: string,
    imgMobile?: string,
    title: string,
    description: string,
}
const animationDuration = 0.8;

export const About: React.FC = () => {
    // Refs
    const aboutRef = useRef<any>(null);
    const whatWeDoRef = useRef(null);
    const bannerRef = useRef(null);
    const peopleRef = useRef(null);

    // Framer
    const scrollSite = useScroll();
    const scalePeople = useTransform(scrollSite.scrollYProgress, [0, 0.5], ['0.95', '1.05']);
    const whyUseScale = useTransform(scrollSite.scrollYProgress, [0, 1], ['80%', '120%']);

    const benefitVariant = {
        initial: {
            opacity: 0,
            x: '100%'
        },
        animate: (index: number) => ({
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.5,
                delay: 0.1 * index
            }
        }),
    };
    const portfolioVariant = {
        initial: {
            opacity: 0,
            y: '-100%'
        },
        animate: (index: number) => ({
            opacity: 1,
            y: 0,
            transition: {
                duration: animationDuration,
                delay: 0.2 * index
            }
        }),
    };
    const lineVariant = {
        initial: {
            borderColor: 'transparent',
            height: 0
        },
        animate: {
            borderColor: 'var(--clr-primary)',
            height: '100%',
            transition: {
                duration: animationDuration,
                delay: 0.2
            }
        }
    };

    // Constants
    const basePortfolioCards: tCard[] = [
        {
            img: '',
            title: 'Local Active Top 25 Equity Portfolio',
            description: 'Direct exposure to top local shares listed on the Johannesburg Stock Exchange (JSE)',
        },
        {
            img: '',
            title: 'Global Active Top 25 Equity Portfolio',
            description: 'Direct offshore exposure to some of the largest and most well-known companies in the world.',
        },
        {
            img: '',
            title: 'Global Passive Balanced ETF Portfolio',
            description: 'Low-cost Balanced ETF portfolio, using Passive Funds in markets outside of South Africa.',
        },
        {
            img: '',
            title: 'Personalised Portfolio',
            description: 'Combination of the aforementioned.',
        },
        {
            img: '',
            title: 'OR',
            description: 'Add a few of your favourite shares to the mix with a non-managed account.',
        },
    ];
    const wrapperCards: tCard[] = [
        {
            img: glacier,
            imgMobile: glacier_mobile,
            title: 'Glacier, by Sanlam',
            description: '',
        },
        {
            img: momentum,
            imgMobile: momentum,
            title: 'Momentum Wealth',
            description: '',
        }
    ];
    const benefitsList: tCard[] = [
        {
            img: global,
            title: 'lowerCost',
            description: 'Lower Cost of Investing by removing unnecessary layers of fees.'
        },
        {
            img: global,
            title: 'realTime',
            description: 'Real-Time access, monitoring and transparency using a world class international platform.'
        },
        {
            img: global,
            title: 'easilyMove',
            description: 'Easily moving between Providers without attracting Fees or Tax.'
        },
        {
            img: global,
            title: 'activelyManaged',
            description: 'Actively Managed Model Portfolios using different strategies, servicing objectives.'
        }
    ]


    // Functions
    const card = (props: tCard, index: number, className?: string, showDetails = true): React.ReactElement => {
        return (
            <motion.div
                variants={portfolioVariant}
                initial={'initial'}
                whileInView={'animate'}
                viewport={{once: true}}
                custom={index}
                className={`card${!className ? '' : ' ' + className}`}>
                {props.img &&
                    <picture>
                        <source media="(max-width: 767px)" srcSet={props.imgMobile ?? props.img}/>
                        <img src={props.img} alt={props.title} role="img" loading={'lazy'}/>
                    </picture>

                }
                {showDetails && props.title && <span className={'title'}>{props.title}</span>}
                {showDetails && props.description && <span className={'description'}>{props.description}</span>}
            </motion.div>
        )
    }

    const headerLine = () => {
        return (
            <motion.div
                variants={lineVariant}
                initial={'initial'}
                whileInView={'animate'}
                viewport={{once: true}}
            />
        )
    };

    return (
        <section id={'about'} className={'about'} ref={aboutRef}>
            {/*What we do?*/}
            <div className={'whatWeDo wrapper'}
                 ref={whatWeDoRef}>
                <div className={'flex flex-column flex-justify-center'}>
                    <h2>{headerLine()}What we do?</h2>
                    <p>Welcome to our realm of expertise where we focus on crafting Managed Personal Share and ETF
                        Portfolios (PSP), offering cost-effective and optimised solutions tailored for the Advisor
                        Market. We
                        cater to both local and offshore requirements, ensuring that our clients benefit from a
                        hands-on,
                        personalised investment experience.</p>
                    <p>Our dedicated team of Discretionary Fund Managers (<strong className={'dfm--black'}>DFMs</strong>) takes a meticulous approach,
                        considering each client&#39;s unique financial goals, risk tolerance, and prevailing market
                        conditions. This
                        personalised strategy not only simplifies wealth management planning but also aims to optimise
                        portfolio performance. Clients can rest assured that their investments are in capable hands,
                        providing them with peace of mind as they navigate their financial journey.</p>
                    <p>Experience the simplicity and effectiveness of our services as we strive to redefine wealth
                        management, making it accessible, tailored, and worry-free for our valued clients.</p>
                </div>
                <div className={'image__wrapper'}>
                    <picture>
                        <source media="(max-width: 767px)" srcSet={whatWeDo_mobile}/>
                        <img className={'image image--whatDoWeDo'} alt={'What we do'} src={whatWeDo} loading={'lazy'}/>
                    </picture>

                </div>
            </div>

            {/*Who we are?*/}
            <motion.div className={'banner'}
                        ref={bannerRef}
                        style={{
                            backgroundImage: `url(${whoWeAreBanner})`,
                            backgroundPositionX: whyUseScale
                        }}>
                <div className={'wrapper'}>
                    <picture>
                        <source media="(max-width: 767px)" srcSet={people_mobile}/>
                        <motion.img
                            className={'image--full'}
                            alt={'People'}
                            src={people}
                            ref={peopleRef}
                            loading={'lazy'}
                        />
                    </picture>
                    <div className={'p-top-lg p-bottom-lg'}>
                        <h2>{headerLine()}Who we are?</h2>
                        <p>
                            Our specialist team of professionals harness the capabilities of robust analytical
                            tools
                            to
                            craft
                            globally accessible Share Portfolio solutions, ensuring a cost effective outcome.
                            Central to
                            our
                            approach is a steadfast commitment to the unwavering consistency of performance, a
                            cornerstone
                            of our process and philosophy.
                        </p>
                        <p>
                            <strong className={'dfm--white'}>DFM<span className={'line'}> | </span>Global</strong> takes pride in its status as an Independent, Management-Owned entity.
                            Our
                            unwavering dedication is evident in our pursuit of delivering boutique-level service
                            paired
                            with
                            globally available, world-class products; all at the most competitive costs. This
                            commitment
                            culminates in a Value for Money offering that stands unparalleled in the market.
                        </p>
                        <p>
                            Discover excellence with <strong className={'dfm--white'}>DFM<span className={'line'}> | </span>Global</strong>, where specialised product focus, independence
                            and
                            a
                            passion for delivering exceptional service converge to redefine the standard in the
                            financial industry.
                        </p>
                    </div>
                </div>
            </motion.div>

            {/*Why use a DFM?*/}
            <div className={'why wrapper'}>
                <div className={'flex flex-column flex-justify-center'}>
                    <h2>{headerLine()}Why use a DFM?</h2>
                    <p>Discover the advantages of aligning with <strong className={'dfm--black'}>DFM<span className={'line'}> | </span>Global</strong>, where the key lies in the division
                        of
                        responsibilities.</p>
                    <p>By partnering with a Discretionary Fund Manager (<strong className={'dfm--black'}>DFM</strong>), you liberate yourself from the
                        continual
                        administrative tasks required to oversee the investment aspects of portfolio management.
                        This
                        encompasses ongoing research, due diligence procedures, and execution processes.
                        Underestimating the significance of this service poses a risk to both the advisor and
                        the
                        client.</p>
                    <p>Empowering advisors, a <strong className={'dfm--black'}>DFM</strong> enables them to dedicate more time to client-facing relations
                        and
                        advice—the very essence of their business.</p>
                    <p>Furthermore, a <strong className={'dfm--black'}>DFM</strong> brings invaluable expertise to the table in portfolio analysis and
                        construction,
                        enhancing the final product offered to the client. This strategic collaboration ensures
                        a
                        well-rounded
                        and enriched investment experience.</p>
                </div>
                <div className={'image__wrapper'}>
                    <picture>
                        <source media="(max-width: 767px)" srcSet={whyUse_mobile}/>
                        <motion.img
                            style={{
                                scale: whyUseScale
                            }}
                            className={'image'}
                            alt={'Why use a DFM'}
                            src={whyUse}
                            loading={'lazy'}
                        />
                    </picture>

                </div>
            </div>

            {/*Are there any benefits by investing directly in Shares or EFT?*/}
            <div className={'wrapper-filled'}>
                <div className={'wrapper'}>
                    <div className={'flex flex-column flex-justify-center'}>
                        <h2>{headerLine()}Are there any benefits by investing directly in Shares or ETFs?</h2>
                        <p>Investing in ETFs / Unit Trusts means you invest or buy units in that fund rather than owning
                            underlying
                            shares or an actual percentage of the listed companies. This potentially results in numerous
                            layers of admin related costs being added to your Effective Annual Cost (EAC). With Personal
                            Share Portfolios (PSP's), YOU legally own the underlying shares in that company, in your own
                            name.</p>
                    </div>
                    <div>
                        <p className={'ul-header'}>There are numerous benefits some of which a few are listed below:</p>
                        <ul>
                            {benefitsList.map((benefit, index) =>
                                <motion.li
                                    key={benefit.title}
                                    variants={benefitVariant}
                                    initial={'initial'}
                                    whileInView={'animate'}
                                    viewport={{once: true}}
                                    custom={index}
                                >
                                    <img src={benefit.img} alt={benefit.title}/>{benefit.description}
                                </motion.li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>

            {/*Combination of the aforementioned*/}
            <div className={'portfolio-models wrapper wrapper-single-column'}>
                <div className={'flex flex-column flex-justify-center'}>
                    <h2>{headerLine()}Base Portfolio Models</h2>
                </div>
                <div className={'wrapper wrapper-quarter-column'}>
                    {card(basePortfolioCards[0], 0)}
                    {card(basePortfolioCards[1], 1)}
                    {card(basePortfolioCards[2], 2)}
                    {card(basePortfolioCards[3], 3)}
                </div>
                {card(basePortfolioCards[4], 4, 'card--sm')}
            </div>


            <div className={'wrapper'}>

                {/*Platforms*/}
                <div className={'platforms wrapper-single-column'}>
                    <div className={'flex flex-column flex-justify-center'}>
                        <h2>{headerLine()}Platforms</h2>
                        <p>All our Personal Share Portfolios [PSPs] are managed on the award-winning SAXO/DMA
                            Platform
                            [DMA]. Investors can avoid unnecessary fees by investing directly on SAXO/DMA.</p>
                        <p>DMA is the locally branded version of Saxo Bank's award-winning platform, providing
                            clients
                            with
                            access to
                            local and global markets.</p>
                    </div>
                    <div className={'image__wrapper'}>
                        <picture>
                            <source media="(max-width: 767px)" srcSet={platforms_mobile}/>
                            <img className={'image'} alt={'Platforms'} src={platforms} loading={'lazy'}/>
                        </picture>

                    </div>
                </div>

                {/*Wrappers*/}
                <div className={'wrappers wrapper-single-column'}>
                    <div className={'flex flex-column flex-justify-center'}>
                        <h2>{headerLine()}Wrappers</h2>
                        <p>Personal Share Portfolios are also offered in wrapped products linked to SAXO/DMA and
                            available on:</p>
                    </div>
                    <div className={'wrapper wrapper-double-column'}>
                        <AnimatePresence>
                            {
                                wrapperCards.map((c, i) => (
                                  <div className={'card__wrapper'} key={c.title}>
                                      {card(c, i, `card--white ${i === 0 ? 'glacierBlue' : ''}`, false)}
                                      <span className={'card__sub-title'}>{c.title}</span>
                                  </div>
                                ))
                            }
                        </AnimatePresence>
                    </div>
                    <div className={'wrapper wrapper-single-column'}>
                        <p>This includes discretionary investments like Endowments or non-discretionary investments
                            like
                            Retirement Annuities (RA's), Preservation Funds and Investment-Linked Living Annuity's
                            (ILLA).</p>
                    </div>
                </div>


            </div>



        </section>
    );
};