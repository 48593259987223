// Created By: Chris
// Created On: 2023/06/17

// React
import React, {useEffect, useState} from 'react';
import './Insights.scss';

// Images
import easyInvesting_1 from '../../assets/Easy Investing Img 1.png';
import easyInvesting_2 from '../../assets/Easy Investing Img 2.png';
import easyInvesting_3 from '../../assets/Easy Investing Img 3.png';
import easyInvesting_1_mobile from '../../assets/_mobile/Easy Investing Img 1.png';
import easyInvesting_2_mobile from '../../assets/_mobile/Easy Investing Img 2.png';
import easyInvesting_3_mobile from '../../assets/_mobile/Easy Investing Img 3.png';
import youtube_link_1 from '../../assets/youtube_link_1.png';

import education_1 from '../../assets/Education Img 1.png';
import education_2 from '../../assets/Education Img 2.png';
import education_3 from '../../assets/Education Img 3.png';
import education_1_mobile from '../../assets/_mobile/Education Img 1.png';
import education_2_mobile from '../../assets/_mobile/Education Img 2.png';
import education_3_mobile from '../../assets/_mobile/Education Img 3.png';

// Shared
import Modal from '../../shared/modal/Modal';

// Framer
import {AnimatePresence, motion} from 'framer-motion';
import YouTube from 'react-youtube';

// Constants
const animationDuration = 0.1;

export const Insights = (props: { onClose: (modalOpen: boolean) => void }): React.ReactElement => {

	const [selectedId, setSelectedId] = useState<any>('');

	// Constants
	type tCard = {
		id: string,
		img: any,
		imgMobile?: any,
		title: string,
		description: string,
		date: string,
		content?: React.ReactElement,
		link?: string,
		video?: boolean
	}

	const InTheMediaCards: tCard[] = [
		{
			id: 'welcomeToBrenthurstWealth',
			img: youtube_link_1,
			imgMobile: youtube_link_1,
			title: 'Welcome to Brenthurst Wealth',
			description: 'South Africa was added to the global grey list of countries in February, which will require more onerous processes for investors and companies. The local economy is in decline, load shedding continues and will likely get worse in months ahead.',
			date: '23/03/2023',
			link: 'https://youtu.be/7TFGmJPqpcM',
			video: true
		},
		{
			id: 'easyInvesting_1',
			img: easyInvesting_1,
			imgMobile: easyInvesting_1_mobile,
			title: 'Comparing Factor Performance this Year',
			description: 'If your portfolio this year was tilted towards large growth or small value this year, you were likely to outperform as these factors were the best performing in both the US and global equity markets. Small growth seemed to struggle the most, while large value also underperformed.',
			date: '25/11/2021',
			link: 'https://mailchi.mp/easyinvesting/easy-investing-newsletter-factors-styles-edition?e=bdee16d2ec'
		},
		{
			id: 'easyInvesting_2',
			img: easyInvesting_2,
			imgMobile: easyInvesting_2_mobile,
			title: 'Fed Announces Taper Plans',
			description: 'The Fed has finally announced tapering, which will see the current $120bn a month Quantitative Easing (QE) programme reduced by $15bn. Tapering refers to the wind-down of QE so it\'s not contractionary in nature, although the market has become accustomed to excess. Lifting...',
			date: '10/11/2021',
			link: 'https://mailchi.mp/easyinvesting/easy-investing-newsletter-the-taper-reveal-edition?e=bdee16d2ec'
		}
		// {
		//     id: 'easyInvesting_3',
		//     img: easyInvesting_3,
		//     imgMobile: easyInvesting_3_mobile,
		//     title: 'A Global Supply Chain Bottleneck',
		//     description: 'This is a theme of this year with supply not being able to support a fast recovery in demand across multiple sectors. Last week we spoke about oil prices surging to the highest level since 2014 due to supply being too slow to meet rapidly recovering demand. This week we look at...',
		//     date: '19/10/2021',
		//     link: 'https://mailchi.mp/dfm/easy-investing-newsletter-supply-chain-edition?e=bdee16d2ec',
		// }
	];
	const educationCards: tCard[] = [
		{
			id: 'education_1',
			img: education_1,
			imgMobile: education_1_mobile,
			title: 'What is a DFM?',
			description: 'A Discretionary Fund Manager (DFM) is mandated to buy and sell investments on behalf of the investor. This contrasts from a Cat 1 advisor mandate, which requires the advisor to first seek permission from the investor to implement portfolio changes. An...',
			date: '',
			content: <React.Fragment>
				<section className={'modal__section'}>
					{/*<div*/}
					{/*    className={'section__image section__image--large section__image--bottom section__image--contain'}*/}
					{/*    style={{backgroundImage: 'url(' + educationImage0 + ')', backgroundPosition: 'center'}}*/}
					{/*    onClick={() => setImageZoom(educationImage0)}*/}
					{/*/>*/}
					<p className={'section__text'}>
						A Discretionary Fund Manager (DFM) is mandated to buy and sell investments on behalf of the
						investor. This contrasts from a Cat 1 advisor mandate, which requires the advisor to first seek
						permission from the investor to implement portfolio changes. An advisor would normally mandate a
						Cat 2 DFM to implement portfolio changes on behalf of the investor.</p>
				</section>

				<section className={'modal__section'}>
					<h6 className={'section__header'}>Advisor Works With the DFM</h6>

					<h6 className={'section__sub-header'}>FSP Category 1 - Advisors & Intermediaries</h6>
					<p className={'section__text'}>A Category 1 licensed Financial Services Provider (FSP) can render
						financial services in the form of advice and/or intermediary services. Advisors would fall in
						this category and can give an investor advice and/or implement investment decisions, but it's up
						to the client to make the final decision.</p>

					<h6 className={'section__sub-header'}>FSP Category 2 – Discretionary FSP</h6>
					<p className={'section__text'}>A Category 2 licensed FSP can render intermediary services of a
						discretionary nature. Investment decisions can be made and implemented according to a
						pre-determined and specified mandate and risk profile.</p>

					<h6 className={'section__sub-header'}>Synergy</h6>
					<p className={'section__text'}>There are 2 critical investment steps:</p>
					<p className={'section__text'}>1) Determine the suitable asset allocation that aligns with the
						investor's goals and risk profile.</p>
					<p className={'section__text'}>2) Select the appropriate investments that fit in with (1) above.</p>
					<p className={'section__text'}>A DFM can assist the advisor and enhance the combined service
						offering.</p>
				</section>

				<section className={'modal__section'}>
					<h6 className={'section__header'}>Benefits of Using DFMs</h6>
					<h6 className={'section__sub-header'}>Research & Systems</h6>
					<p className={'section__text'}>DFMs have the sole focus of constructing and managing portfolios.
						They have access to systems like Bloomberg and Morningstar to assist in the process. These
						systems are often inaccessible to advisors due to the high cost. </p>

					<h6 className={'section__sub-header'}>Client Focus</h6>
					<p className={'section__text'}>Managing portfolios is a full-time job. As is servicing clients with
						advice. By including a DFM, the advisor can delegate the task of constructing portfolios and
						conducting due diligence. This allows the advisor to solely focus on the client
						relationship. </p>

					<h6 className={'section__sub-header'}>Integrated Offering</h6>
					<p className={'section__text'}>A close collaboration between advisor and DFM ensures a suitable fit
						between client needs and the resulting product.</p>
				</section>

				<section className={'modal__section'}>
					<h6 className={'section__header'}>Partner With DFM Global</h6>
					<ul>
						<li>Own branded proposals and marketing material. Higher client conversions with a professional
							and in-depth portfolio comparison.
						</li>
						<li>Transparency allowing full integration with the advice process for a holistic portfolio
							view.
						</li>
						<li>We do the heavy lifting. From proposal and meeting assistance to the implementation and
							admin process.
						</li>
						<li>Our expertise in cost effective products with a global reach is what sets us apart.</li>
					</ul>
				</section>
			</React.Fragment>
		},
		{
			id: 'education_2',
			img: education_2,
			imgMobile: education_2_mobile,
			title: 'Benefits of a Personal Share Portfolio',
			description: 'The technology age has brought information to our finger tips and has overhauled many industries. Investments is among those industries that have experienced the most change. Stock market participants as a whole are more informed and with better information...',
			date: '',
			content: <React.Fragment>
				<section className={'modal__section'}>
					<h6 className={'section__header'}>Route 1 - Direct Investing Could Be Best for You</h6>
					<h6 className={'section__sub-header'}>Better Reach, Control and Costs with DFM Global Personal Share
						Portfolios</h6>
					<p className={'section__text'}>The technology age has brought information to our finger tips and has
						overhauled many industries. Investments is among those industries that have experienced the most
						change. Stock market participants as a whole are more informed and with better information,
						comes better pricing. Opportunities to find “mispriced” stocks and generate alpha has steadily
						declined. You are no longer ahead of the pack if you check prices daily in the newspaper and
						read the company’s annual report sent through the mail. No, these numbers are already on screen
						and summarised the moment they are published. </p>
					<p className={'section__text'}>Paying 2.00% for an asset manager to try and “pick the winners” is no
						longer a reliable way to boost your share portfolio. You now need to ask yourself the question,
						what is worth paying for? We think a direct approach to investing with Personal Share Portfolios
						(PSP) could be the answer.</p>
				</section>

				{/*PSPs: The Basics*/}
				<section className={'modal__section'}>
					<h6 className={'section__sub-header'}>PSPs: The Basics</h6>
					<p className={'section__text'}>A personal share portfolio is an account offered by a DFM (or Asset
						Manager) that is opened in the investor’s own name. Capital is not “pooled” in a fund or unit
						trust with other investors so the investor buys listed companies directly on a stock exchange
						and the company’s share register will show the name of the investor. PSPs can be self-managed or
						managed by the DFM.</p>
				</section>

				{/*A Market First DFM Service*/}
				<section className={'modal__section'}>
					<h6 className={'section__sub-header'}>A Market First DFM Service</h6>
					<p className={'section__text'}>DFM Global is bringing an innovative service to advisors for the
						benefit of their clients. We specialise in local and offshore Personal Share Portfolios that
						cater to the investor’s needs and this is where we really mean what we say. Flexible options
						with investing allow the investor and advisor to implement any desired strategy and DFM Global
						can provide the toolkit. </p>
					<p className={'section__text'}>We can provide managed or non-managed portfolios, but why not both?
						If you want to sit a large portion of your capital in global equity ETF to sit passively and
						grow for years then that part of the portfolio can be split into a non-managed portion. Don’t
						pay a portfolio manager for zero value add. If the task of picking your own investments seems
						daunting, then DFM Global has solutions that can help you. The flexibility doesn’t end there.
						Are you positive on rising industries like Artificial Intelligence and Green Energy? We can
						bring that into the solution. Our strategies can target growth regions, dynamic asset allocation
						and customize the risk profile of the portfolio. Financial planning is made easier. And the best
						part is that the costs are extremely competitive. Why can we charge the lowest fees in the
						industry? It’s our approach.</p>
					<p className={'section__text'}>A small team of individuals highly skilled in quantitative modelling
						and portfolio construction with the best data systems in the world (Bloomberg, Morningstar,
						etc.) to assist. Large companies with several floors of analysts no longer have the advantage
						they used to and that costly structure doesn’t benefit the investor. This is not a new concept,
						but now the South African market has another arrow in the quiver.</p>
				</section>

				{/*Cost Effective Solution*/}
				<section className={'modal__section'}>
					<h6 className={'section__sub-header'}>Cost Effective Solution</h6>
					<p className={'section__text'}>Fees can eat away at the portfolio’s value, which gets compounded
						over time. Direct is better. Funds could have layers of fees for example: the Satrix MSCI World
						ETF has a management fee of 0.15% but a Total Expense Ratio of 0.35%. Where does the extra 0.20%
						come from? Well, the ETF allocates 100% to iShares Core MSCI World ETF, which itself has a
						management fee of 0.20%. Instead of using Satrix, you can save 0.15% by investing directly in
						iShares. That might not sound like a large amount, but your fees are 75% higher by not going the
						direct route. When going for passive index tracking ETFs, you want the lowest possible cost.</p>
					<p className={'section__text'}>Looking for a managed solution? You’ll find our management fees are
						extremely competitive. There is no additional platform or admin costs. We also don’t charge
						performance fees. Delivering top performance is in the job description. </p>
					<p className={'section__text'}>Portfolio transfers are free and if you are unhappy with the service,
						then we’ll make it free and easy to transfer out. Our admin staff will work with the advisor to
						ensure a quick process. Now that’s value for money.</p>
				</section>

				{/*A Global Reach for Your Portfolio*/}
				<section className={'modal__section'}>
					<h6 className={'section__sub-header'}>A Global Reach for Your Portfolio</h6>
					<p className={'section__text'}>Gain access to shares listed on global stock exchanges. By not
						investing in foreign listed companies, you are depriving yourself of over 99% of the investment
						universe. All the industries and products that are going to change the way we do things, like
						electric- and autonomous vehicles and cloud computing, are going to come from foreign companies.
						You are handicapping yourself if you are not investing in these companies. </p>
					<p className={'section__text'}>We take you straight to the source of growth. Forget Naspers and its
						complicated structures. If you want Chinese tech, own Tencent, or better yet, add Alibaba to
						your portfolio, a company that is the Eastern version of Amazon, Visa and Mastercard rolled up
						into one. There is also the ability to include thematic ETFs that target growing industries like
						Clean Energy or Esports. Strict regulations on unit trusts often exclude these ETFs for
						inclusion. </p>
					<p className={'section__text'}>Comparing the local ETF providers against global providers, it’s
						clear where you’ll find more options.</p>

					<div className={'d-flex gap--3 justify--center'} style={{marginBottom: '3em', flexWrap: 'wrap'}}>
						{/*<div className={'section__image section__image--contain'}*/}
						{/*     onClick={() => setImageZoom(educationImage1__0)}*/}
						{/*     style={{*/}
						{/*         backgroundImage: 'url(' + educationImage1__0 + ')',*/}
						{/*         backgroundPosition: 'center',*/}
						{/*         flex: '1 0 35em'*/}
						{/*     }}/>*/}
						{/*<div className={'section__image section__image--contain'}*/}
						{/*     onClick={() => setImageZoom(educationImage1__1)}*/}
						{/*     style={{*/}
						{/*         backgroundImage: 'url(' + educationImage1__1 + ')',*/}
						{/*         backgroundPosition: 'center',*/}
						{/*         flex: '1 0 35em'*/}
						{/*     }}/>*/}
					</div>

					<p className={'section__text'}>Personal Share Portfolios are nimble enough to include small caps in
						a meaningful quantity, further increasing your options relative to an investment in unit trusts
						(which may be too large to take up a meaningful allocation in a small company).</p>
				</section>

				{/*Control Everything, See Everything*/}
				<section className={'modal__section'}>
					<h6 className={'section__sub-header'}>Control Everything, See Everything</h6>
					<p className={'section__text'}>It’s your portfolio and you have the control. DMA(SAXO) is the
						preferred platform for DFM Global, giving the investor real-time information on portfolio
						holdings and performance. It has complete transparency, whether the portfolio is self-managed or
						managed by DFM Global so you can always see your current holdings and any changes made to the
						portfolio. Should you wish to change provider, that can easily be done without selling your
						portfolio and potentially triggering capital gains tax.</p>
					<p className={'section__text'}>Investors can participate in corporate actions like the Naspers and
						Prosus switch and make the selection that holds the most benefit or leave those decisions to
						DFM. The point is, you can choose even if it means choosing who makes certain decisions.</p>
				</section>

				{/*Tax Implications? There is a Solution*/}
				<section className={'modal__section'}>
					<h6 className={'section__sub-header'}>Tax Implications? There is a Solution</h6>
					<p className={'section__text'}>One disadvantage of Personal Share Portfolios is that the individual
						can be taxed on realised capital gains when trades are made in the portfolio. This is different
						to unit trusts where only a redemption of the investment may trigger a tax event while trades
						have no impact on tax. Fortunately, there are structures that can be used to negate the tax
						implications of Personal Share Portfolios.</p>
				</section>

				{/*What More Do You Need to Know?*/}
				<section className={'modal__section'}>
					<h6 className={'section__sub-header'}>What More Do You Need to Know?</h6>
					<p className={'section__text'}>DFM Global works with the advisor. We analyse the existing portfolio
						and at the direction of the advisor, build a proposal that contains a comparison report with
						complete asset look-through. Know the yield and income of the portfolio, sector- and regional
						exposure as well as asset allocation. Plus, an implementation report that identifies that
						capital gain and assists with tax planning to maximise after tax returns. We can also give a
						holistic view of the investor’s portfolio with any other portfolio information you want
						displayed.</p>
					<p className={'section__text'}>A direct approach with flexibility, transparency, control, access to
						a DFM with solutions to fit any need and all this at unbelievable low cost. Now that is quite a
						combination. </p>
				</section>
			</React.Fragment>
		},
		{
			id: 'education_3',
			img: education_3,
			imgMobile: education_3_mobile,
			title: 'Characteristics of an Index ETF.',
			description: 'Investor\'s capital is pooled and invested in underlying instruments. The underlying instruments are usually shares of a company, but can also be bonds or derivatives like options, futures and swaps.',
			date: '',
			content: <React.Fragment>

				<section className={'modal__section'}>
					{/*<div*/}
					{/*    onClick={() => setImageZoom(educationImage2)}*/}
					{/*    className={'section__image section__image--medium section__image--bottom section__image--contain'}*/}
					{/*    style={{backgroundImage: 'url(' + educationImage2 + ')', backgroundPosition: 'center'}}*/}
					{/*/>*/}
					<h6 className={'section__sub-header'}>It’s a Fund</h6>
					<p className={'section__text'}>Investor's capital is pooled and invested in underlying instruments.
						The underlying instruments are usually shares of a company, but can also be bonds or derivatives
						like options, futures and swaps. </p>
				</section>

				<section className={'modal__section'}>
					<h6 className={'section__sub-header'}>Exchange Traded</h6>
					<p className={'section__text'}>ETFs are bought and sold on a stock exchange and can be bought in a
						share trading account like any other stock.</p>
				</section>

				<section className={'modal__section'}>
					<h6 className={'section__sub-header'}>Index Tracker</h6>
					<p className={'section__text'}>ETFs usually invest according to an index that often tracks a market
						segment. This can be regional equity, bonds or themes like Green Energy.</p>
				</section>

				<section className={'modal__section'}>
					<h6 className={'section__sub-header'}>Competitive Cost</h6>
					<p className={'section__text'}>Because ETFs usually track an index according to a set of rules, they
						are considered passive investments and hence charge lower management fees.</p>
				</section>

				<section className={'modal__section'}>
					<h6 className={'section__sub-header'}>Transparency</h6>
					<p className={'section__text'}>The price of an ETF is easily accessible like any other traded stock.
						The underlying holdings are also often disclosed.</p>
				</section>
				<section className={'modal__section'}>
					<h6 className={'section__sub-header'}>Liquidity</h6>
					<p className={'section__text'}>Like any other traded instrument, ETFs need a market of buyers and
						sellers in order for you to make a trade. Smaller ETFs may be traded less frequently.</p>
				</section>

			</React.Fragment>
		}
	];
	const cardVariant = {
		initial: {
			boxShadow: '0 3px 6px var(--clr-black-02)'
		},
		animate: {
			boxShadow: '0 6px 12px var(--clr-black-02)',
			transition: {
				duration: animationDuration
			}
		}
	};
	const cardImgVariant = {
		initial: {
			scale: 1
		},
		animate: {
			scale: 1.1,
			transition: {
				duration: animationDuration * 3
			}
		}
	};
	const lineVariant = {
		initial: {
			borderColor: 'transparent',
			height: 0
		},
		animate: {
			borderColor: 'var(--clr-primary)',
			height: '100%',
			transition: {
				duration: animationDuration,
				delay: 0.2
			}
		}
	};
	const videoId = '7TFGmJPqpcM';

	// States
	const [modalOpen, setModalOpen] = useState<boolean | undefined>(undefined);

	useEffect(() => {
		if (modalOpen !== undefined) props.onClose(modalOpen);
	}, [modalOpen]);

	const card = (props: tCard, className?: string): React.ReactElement => {

		const buttonText = props.video ? 'Watch Video' : 'Read More';

		const handleOnClick = (e: any) => {
			e.stopPropagation();

			if (props.link) {
				window.open(props.link, '_blank');
				return;
			}
			setModalOpen(!modalOpen);
			setSelectedId(props.id);
		};

		return (
			<motion.div
				className={`card${!className ? '' : ' ' + className}`}
				key={props.title}
				variants={cardVariant}
				initial={'initial'}
				whileHover={'animate'}
				onClick={(e) => handleOnClick(e)}
			>
				{props.img &&
					<picture>
						<source media="(max-width: 767px)" srcSet={props.imgMobile ?? props.img}/>
						<motion.img
							variants={cardImgVariant}
							src={props.img}
							loading={'lazy'}
						/>
					</picture>

				}
				{props.date && <span className={'date'}>{props.date}</span>}
				{props.title && <span className={'title'}>{props.title}</span>}
				{props.description && <span className={'description'}>{props.description}</span>}
				<button onClick={(e) => handleOnClick(e)}>
					{buttonText}
				</button>
			</motion.div>
		);
	};

	const headerLine = () => {
		return (
			<motion.div
				variants={lineVariant}
				initial={'initial'}
				whileInView={'animate'}
				viewport={{once: true}}
			/>
		);
	};

	const getSelectedCard = (): React.ReactElement => {
		if (!selectedId) return <></>;
		let selectedCard = InTheMediaCards.find((c) => c.id === selectedId);

		if (!selectedCard) selectedCard = educationCards.find((c) => c.id === selectedId);
		if (!selectedCard) return <></>;

		return <>
			<h2>{selectedCard.title}</h2>
			{selectedCard.content}
		</>;
	};

	return (
		<section id={'insights'} className={'insights'}>
			{/*Easy Investing*/}
			<div className={'wrapper wrapper-single-column'}>
				<h2>{headerLine()}In the Media</h2>
				<div className={'wrapper wrapper-third-column'}>
					{
						InTheMediaCards.map((c) => (
							card(c, 'card--white')
						))
					}
				</div>
			</div>

			{/*Education*/}
			<div className={'wrapper wrapper-single-column'}>
				<h2>{headerLine()}Education</h2>
				<div className={'wrapper wrapper-third-column'}>
					{
						educationCards.map((c) => (
							card(c, 'card--blue')
						))
					}
				</div>
			</div>

			<AnimatePresence
				initial={false}
				mode={'wait'}
				onExitComplete={() => null}
			>
				{selectedId && <Modal
					backdropImage={[...educationCards, ...InTheMediaCards]?.find((c) => c.id === selectedId)}
					layoutId={selectedId}
					open={modalOpen ?? false}
					handleClose={() => {
						setModalOpen(false);
						setSelectedId('');
					}}
				>
					{getSelectedCard()}
				</Modal>}
			</AnimatePresence>
		</section>
	);
};