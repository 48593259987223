// Created By: Chris
// Created On: 2023/06/17

// React
import React, {ReactNode} from 'react';
import './Modal.scss';

// @ts-ignore
import {Link as ScrollLink} from 'react-scroll';

// Framer
import {motion} from 'framer-motion';

const Modal = (props: {
    open: boolean,
    layoutId: string,
    children: ReactNode,
    handleClose: () => void,
    backdropImage?: any
    disableBackdrop?: boolean
}): React.JSX.Element => {

    const dropIn = {
        hidden: {
            y: "-100vh",
            opacity: 0,
        },
        visible: {
            y: "0",
            opacity: 1,
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 500,
            },
        },
        exit: {
            y: "100vh",
            opacity: 0,
        },
    };

    return props.open ? (
        <motion.div
            layoutId={props.layoutId}
            className={'backdrop'}
            onClick={() => !props.disableBackdrop && props.handleClose()}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <motion.div
                onClick={(e) => {
                    e.stopPropagation()
                }}
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
                className={'modal'}
            >
                <i onClick={() => props.handleClose()}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                        <path
                            d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
                    </svg>
                </i>
                {props.children}
            </motion.div>

        </motion.div>
    ) : <></>;
};

export default Modal;