// Created By: Chris
// Created On: 2023/11/07

// React
import React, {useEffect, useRef, useState} from 'react';

// Styles
import './Header.scss';

// @ts-ignore
import {Link as ScrollLink} from 'react-scroll';

// Framer
import {motion, useScroll} from 'framer-motion';

// Images
import logo from '../../assets/Logo.png';
import xIcon from "../../assets/X Icon.png";

export const Header = () => {
    // Constants
    const duration = 300;
    const smooth = true;

    // Refs
    const prevScrollY = useRef(0);
    const headerRef = useRef(null);

    // Framer
    const {scrollYProgress} = useScroll();

    // States
    const [scrollDirection, setScrollDirection] = useState<string>('up');
    const [toggleNavigation, setToggleNavigation] = useState<boolean>(true);
    const [isScrolledToTop, setIsScrolledToTop] = useState(true);

    // Effects
    useEffect(() => {
        if (!toggleNavigation) return;

        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            setIsScrolledToTop(currentScrollY < 20);

            if (currentScrollY > prevScrollY.current) {
                setScrollDirection('down');
            } else if (currentScrollY < prevScrollY.current) {
                setScrollDirection('up');
            }

            prevScrollY.current = currentScrollY;


        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [toggleNavigation]);

    return (
        <header id={'header'}
                className={`header${toggleNavigation ? '' : ' header--visible'}${scrollYProgress.get() > 0.1 ? ' header--filled' : ''}`}
                ref={headerRef}
                role={'banner'}>
            <div className={`header__wrapper ${scrollDirection}`}>
                <ScrollLink to={'home'} smooth={smooth} duration={duration}>
                    <div>
                        <h1>DFM <span className={'line'}></span><span className={'inner-header'}>Global</span></h1>
                        <span className={'sub-header'}>Your Dedicated Investment Partner</span>
                    </div>
                </ScrollLink>
                <nav>
                    <ul>
                        <li>
                            <ScrollLink to={'home'} smooth={smooth} duration={duration}>
                                Home
                            </ScrollLink>
                        </li>
                        <li>
                            <ScrollLink to={'about'} smooth={smooth} duration={duration}>
                                About
                            </ScrollLink>
                        </li>
                        <li>
                            <ScrollLink to={'insights'} smooth={smooth} duration={duration}>
                                Insights
                            </ScrollLink>
                        </li>
                        <li>
                            <a target="_blank" rel="noopener noreferrer"
                               href={'https://us7.list-manage.com/contact-form?u=6c24dfdc6b1a736de10987833&form_id=0657c3cda38c8c9d6dc0b6453f5978a8'}>
                                Get Started
                            </a>
                        </li>
                    </ul>
                </nav>

                {/*TOGGLE ICON*/}
                <i title={'Toggle navigation'}
                   className={'toggle'}
                   onClick={() => {
                       if (!toggleNavigation && !isScrolledToTop) setScrollDirection('down');
                       setToggleNavigation(!toggleNavigation);
                   }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                        <path d="M120-240v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z"/>
                    </svg>
                </i>
            </div>

            <nav className={`mobile__nav ${toggleNavigation ? 'mobile__nav--hidden' : 'mobile__nav--visible'}`}>
                <ul>
                    <li>
                        <ScrollLink to={'home'} smooth={true} duration={500}
                                    onClick={() => setToggleNavigation(!toggleNavigation)}>
                            Home
                        </ScrollLink>
                    </li>
                    <li>
                        <ScrollLink to={'about'} smooth={true} duration={500}
                                    onClick={() => setToggleNavigation(!toggleNavigation)}>
                            About
                        </ScrollLink>
                    </li>
                    <li>
                        <ScrollLink to={'insights'} smooth={true} duration={500}
                                    onClick={() => setToggleNavigation(!toggleNavigation)}>
                            Insights
                        </ScrollLink>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer"
                           href={'https://us7.list-manage.com/contact-form?u=6c24dfdc6b1a736de10987833&form_id=0657c3cda38c8c9d6dc0b6453f5978a8'}>
                            Get Started
                        </a>
                    </li>
                </ul>
                <i onClick={() => setToggleNavigation(!toggleNavigation)} style={{opacity: 1}}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                        <path
                            d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
                    </svg>
                </i>
            </nav>
        </header>
    );
};