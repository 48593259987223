// Created By: Chris
// Created On: 2023/06/17

// React
import React, {useEffect, useState} from 'react';
import './Contact.scss';

// Images
import contactNewsletterBanner from '../../assets/ContactNewsletterBanner.jpg';

// Framer
import {AnimatePresence, motion} from 'framer-motion';

// Shared
import Modal from '../../shared/modal/Modal';

// Constants
const animationDuration = 0.8;
const lineVariant = {
	initial: {
		borderColor: 'transparent',
		height: 0
	},
	animate: {
		borderColor: 'var(--clr-primary)',
		height: '100%',
		transition: {
			duration: animationDuration,
			delay: 0.2
		}
	}
};
type tFormData = {
	FNAME: string;
	LNAME: string;
	EMAIL: string;
	MMERGE3: {
		area: string;
		detail1: string;
		detail2: string;
	};
	LOCATION: string;
	CMPANY: string;
	'group[23233][1]': string;
	'group[23233][4]': string;
	'group[23233][2]': string;
	'group[23237][32]': string;
	'group[23237][64]': string;
	'group[23237][128]': string;
	'group[23237][256]': string;
	'group[85230][8]': string;
	'group[85230][16]': string;
	tags: string
};

export const Contact: React.FC = () => {

	// States
	const [formData, setFormData] = useState<tFormData>({
		FNAME: '',
		LNAME: '',
		EMAIL: '',
		MMERGE3: {
			area: '',
			detail1: '',
			detail2: ''
		},
		LOCATION: '',
		CMPANY: '',
		'group[23233][1]': '',
		'group[23233][4]': '',
		'group[23233][2]': '',
		'group[23237][32]': '',
		'group[23237][64]': '',
		'group[23237][128]': '',
		'group[23237][256]': '',
		'group[85230][8]': '',
		'group[85230][16]': '',
		tags: '8261538'
	});
	const [formDirty, setFormDirty] = useState<boolean>(false);
	const [modalOpen, setModalOpen] = useState<boolean | undefined>(undefined);
	const [selectedId, setSelectedId] = useState<any>('');

	// Effects
	useEffect(() => {
		console.log(formData);
	}, [formData]);

	const headerLine = () => {
		return (
			<motion.div
				variants={lineVariant}
				initial={'initial'}
				whileInView={'animate'}
				viewport={{once: true}}
			/>
		);
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const {name, value} = event.target;

		// Check if the property is a nested property (e.g., MMERGE3[area])
		const isNestedProperty = name.includes('[');

		if (isNestedProperty) {
			// Extract the nested property name and key from the input name
			const [propertyName, key] = name.split('[').map((str) => str.replace(']', ''));
			setFormData((prevFormData) => ({
				...prevFormData,
				MMERGE3: {
					...prevFormData.MMERGE3,
					[key]: value
				}
			}));
		} else {
			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: value
			}));
		}
	};

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {name, checked} = e.target;

		//if(e.target?.checked) value =

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: checked
		}));
	};

	const error = () => {
		return (formDirty && <div className="errorText">Please enter a value</div>);
	};

	return (
		<section id={'contact'} className={'contact'}>
			<div className={'container'}>
				<h2>{headerLine()}Tweets</h2>
				<div className={'inner'}>
					<div className={'wrapper wrapper-single-column'}
							 style={{backgroundImage: `url(${contactNewsletterBanner})`}}>
						<div className={'flex flex-column flex-align-center flex-gap-lg'}>
							<span>Receive insights into making money work with investments by signing up to our newsletter</span>
							<button onClick={() => {
								setModalOpen(true);
								setSelectedId('subscribe');
							}}>
								Subscribe
							</button>
						</div>
					</div>
					<div className={'wrapper wrapper-single-column'}>

						{/*<blockquote className="twitter-tweet">*/}

						{/*	<p lang="en" dir="ltr">*/}
						{/*		Exploring the S&amp;P 500&#39;s journey since*/}
						{/*		2000:<br></br>📈 2008 financial crises &amp; 2020 Covid pandemic = peak volatility!<br></br>🔍 In &#39;08,*/}
						{/*		S&amp;P swung from +14.5% to -9.85% over 3 days<br></br>🌟 ‘10 took the spotlight with a 14-day positive*/}
						{/*		streak— that’s nearly 3 weeks!*/}
						{/*	</p>&mdash; DFM (@DFMGlobal)*/}
						{/*	<a href="https://twitter.com/DFMGlobal/status/1726889691826565188?ref_src=twsrc%5Etfw">November 21,*/}
						{/*		2023</a>*/}

						{/*</blockquote>*/}

						{/*<blockquote className="twitter-tweet">*/}

						{/*	<p lang="en" dir="ltr">This is such a great picture of where we are in*/}
						{/*		the markets!<br></br>As probability of a Rate Cut in Mar24 change, so should the Asset values or Yield; US*/}
						{/*		Bond Yields corretly reacted, while S&amp;P500 just powers on...<br></br>via <a*/}
						{/*			href="https://twitter.com/DFMGlobal?ref_src=twsrc%5Etfw">@DFMGlobal</a> and data by BLOOMBERG<br></br>Weekly*/}
						{/*	Data, YTD is since 25Dec23 <a href="https://t.co/XlSTDmSt6X">https://t.co/XlSTDmSt6X</a> <a*/}
						{/*	href="https://t.co/g1u1D8OaA0">pic.twitter.com/g1u1D8OaA0</a>*/}
						{/*</p>*/}

						{/*	&mdash; JC Louw (@jc_louw)*/}
						{/*	<a href="https://twitter.com/jc_louw/status/1754389208683163733?ref_src=twsrc%5Etfw">February 5, 2024</a>*/}
						{/*</blockquote>*/}


						<blockquote className="twitter-tweet"><p lang="en" dir="ltr">Webinar: Four expert speakers will talk about
							markets and investing and share insights about how to build a global investment portfolio. <a
								href="https://twitter.com/MagnusHeystek?ref_src=twsrc%5Etfw">@MagnusHeystek</a> <a
								href="https://twitter.com/Brian_Butchart?ref_src=twsrc%5Etfw">@Brian_Butchart</a> <a
								href="https://twitter.com/jc_louw?ref_src=twsrc%5Etfw">@jc_louw</a> Register here: <a
								href="https://t.co/5X4eqo9GTj">https://t.co/5X4eqo9GTj</a> <a
								href="https://twitter.com/hashtag/investing?src=hash&amp;ref_src=twsrc%5Etfw">#investing</a> <a
								href="https://twitter.com/hashtag/financialplanning?src=hash&amp;ref_src=twsrc%5Etfw">#financialplanning</a>
							<a href="https://t.co/2HJleADexf">pic.twitter.com/2HJleADexf</a></p>&mdash; Brenthurst Wealth
							(@BrenthurstSA) <a href="https://twitter.com/BrenthurstSA/status/1767773688944857564?ref_src=twsrc%5Etfw">March
								13, 2024</a></blockquote>
					</div>
				</div>
			</div>

			<AnimatePresence
				initial={false}
				mode={'wait'}
				onExitComplete={() => null}
			>
				{selectedId &&
					<Modal
						disableBackdrop={true}
						layoutId={selectedId}
						open={modalOpen ?? false}
						handleClose={() => {
							setModalOpen(false);
							setFormDirty(false);
							setSelectedId('');
						}}
					>
						<div id={'mc_embed_signup'}>
							<form
								action={'https://dfm.us7.list-manage.com/subscribe/post?u=6c24dfdc6b1a736de10987833&amp;id=7e557d50a7&amp;f_id=0025e6e4f0'}
								method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate"
								target="_blank">

								<div id="mc_embed_signup_scroll"><h2>DFM|GLOBAL Newsletter</h2>

									<div className="mc-field-group"><label htmlFor="mce-FNAME">First Name <span
										className="asterisk">*</span></label>
										<input type="text"
													 required
													 name="FNAME"
													 className=" text"
													 id="mce-FNAME"
													 value={formData.FNAME}
													 onChange={handleInputChange}
										/>
										{!formData.FNAME && error()}
									</div>
									<div className="mc-field-group"><label htmlFor="mce-LNAME">Last Name <span
										className="asterisk">*</span></label>
										<input type="text"
													 required
													 name="LNAME"
													 className=" text"
													 id="mce-LNAME"
													 value={formData.LNAME}
													 onChange={handleInputChange}
										/>
										{!formData.LNAME && error()}
									</div>
									<div className="mc-field-group"><label htmlFor="mce-EMAIL">Email Address <span
										className="asterisk">*</span></label>
										<input
											type="email"
											name="EMAIL"
											className="required email"
											id="mce-EMAIL"
											required
											value={formData.EMAIL}
											onChange={handleInputChange}
										/>
										{!formData.LNAME && error()}
									</div>
									<div className="mc-field-group">
										<label htmlFor="mce-MMERGE3">Phone Number <span
											className="asterisk">*</span></label>
										<div className="phonefield phonefield-us">(<span className="phonearea">
								<input
									required
									className="phonepart REQ_CSS"
									pattern="[0-9]*"
									id="mce-MMERGE3-area"
									name="MMERGE3[area]"
									size={3}
									placeholder={'###'}
									maxLength={3}
									type="text"
									value={formData.MMERGE3.area}
									onChange={handleInputChange}
								/>
							</span>)<span
											className="phonedetail1">
								<input className="phonepart REQ_CSS" pattern="[0-9]*"
											 id="mce-MMERGE3-detail1" name="MMERGE3[detail1]" size={3}
											 maxLength={3} type="text"
											 placeholder={'###'}
											 value={formData.MMERGE3.detail1}
											 onChange={handleInputChange}
								/></span>-<span className="phonedetail2">
								<input className="phonepart REQ_CSS" pattern="[0-9]*"
											 id="mce-MMERGE3-detail2" name="MMERGE3[detail2]" size={4}
											 placeholder={'####'}
											 maxLength={4} type="text" value={formData.MMERGE3.detail2}
											 onChange={handleInputChange}/></span></div>
										{(!formData.MMERGE3.area || !formData.MMERGE3.detail1 || !formData.MMERGE3.detail2) && error()}
									</div>
									<div className="mc-field-group"><label htmlFor="mce-LOCATION">Location <span
										className="asterisk">*</span></label><select name="LOCATION"
																																 required
																																 className=""
																																 id="mce-LOCATION"
																																 value={formData.LOCATION}
																																 onChange={handleInputChange}
									>
										<option value=""></option>
										<option value="Eastern Cape">Eastern Cape</option>
										<option value="Freestate">Freestate</option>
										<option value="Gauteng">Gauteng</option>
										<option value="Kwazulu-Natal">Kwazulu-Natal</option>
										<option value="Limpopo">Limpopo</option>
										<option value="Mpumalanga">Mpumalanga</option>
										<option value="North-West">North-West</option>
										<option value="Northern Cape">Northern Cape</option>
										<option value="Western Cape">Western Cape</option>
									</select>
										{(!formData.LOCATION) && error()}
									</div>
									<div className="mc-field-group"><label htmlFor="mce-CMPANY">Company<span className="asterisk"></span></label><input
										type="text"
										name="CMPANY"
										className=" number"
										id="mce-CMPANY"
										value={formData.CMPANY}
										onChange={handleInputChange}/>
										{(!formData.LOCATION) && error()}
									</div>

									<div className="mc-field-group input-group"><label>Interested in: <span
										className="asterisk"></span></label>
										<ul>
											<li><input type="checkbox" name="group[23233][1]" id="mce-group[23233]-23233-0"
																 value={formData['group[23233][1]']} onChange={handleCheckboxChange}/><label
												htmlFor="mce-group[23233]-23233-0">Local Share and ETF Portfolios (PSPs)</label></li>
											<li><input type="checkbox" name="group[23233][4]" id="mce-group[23233]-23233-1"
																 value={formData['group[23233][4]']} onChange={handleCheckboxChange}/><label
												htmlFor="mce-group[23233]-23233-1">Global Share and ETF Portfolios (PSPs)</label></li>
											<li><input type="checkbox" name="group[23233][2]" id="mce-group[23233]-23233-2"
																 value={formData['group[23233][2]']} onChange={handleCheckboxChange}/><label
												htmlFor="mce-group[23233]-23233-2">Unit Trusts</label></li>
										</ul>
									</div>
									<div className="mc-field-group input-group"><label>How long have you been in the market? <span
										className="asterisk"></span></label>
										<ul>
											<li><input type="checkbox" name="group[23237][32]" id="mce-group[23237]-23237-0"
																 value={formData['group[23237][32]']} onChange={handleCheckboxChange}/><label
												htmlFor="mce-group[23237]-23237-0">Less than 1 year (beginner)</label></li>
											<li><input type="checkbox" name="group[23237][64]" id="mce-group[23237]-23237-1"
																 value={formData['group[23237][64]']} onChange={handleCheckboxChange}/><label
												htmlFor="mce-group[23237]-23237-1">1 to 5 years (still learning)</label></li>
											<li><input type="checkbox" name="group[23237][128]" id="mce-group[23237]-23237-2"
																 value={formData['group[23237][128]']} onChange={handleCheckboxChange}/><label
												htmlFor="mce-group[23237]-23237-2">6 to 10 years (advanced)</label></li>
											<li><input type="checkbox" name="group[23237][256]" id="mce-group[23237]-23237-3"
																 value={formData['group[23237][256]']} onChange={handleCheckboxChange}/><label
												htmlFor="mce-group[23237]-23237-3">Over 10 years (guru)</label></li>
										</ul>
									</div>
									<div className="mc-field-group input-group"><label>Do you need an advisor? <span
										className="asterisk"></span></label>
										<ul>
											<li><input type="checkbox" name="group[85230][8]" id="mce-group[85230]-85230-0"
																 value={formData['group[85230][8]']} onChange={handleCheckboxChange}/><label
												htmlFor="mce-group[85230]-85230-0">Yes</label></li>
											<li><input type="checkbox" name="group[85230][16]" id="mce-group[85230]-85230-1"
																 value={formData['group[85230][16]']} onChange={handleCheckboxChange}/><label
												htmlFor="mce-group[85230]-85230-1">No</label></li>
										</ul>
									</div>

									<input type="hidden" name="tags" value="8261538"/>

									<div id="mce-responses" className="clear">

										<div className="response" id="mce-error-response" style={{display: 'none'}}></div>

										<div className="response" id="mce-success-response" style={{display: 'none'}}></div>

									</div>
									<div aria-hidden="true" style={{position: 'absolute', left: '-5000px'}}>
										<input type="text"
													 name="b_6c24dfdc6b1a736de10987833_7e557d50a7"
													 value=""/></div>
									<div className="clear"><input type="submit" name="subscribe" id="mc-embedded-subscribe"
																								className="button"
																								onClick={() => setFormDirty(true)}
																								value="Subscribe"/>
									</div>

								</div>

							</form>
						</div>
					</Modal>}
			</AnimatePresence>
		</section>
	);
};