// Created By: Chris
// Created On: 2023/06/17

// React
import React from 'react';
import './Footer.scss';

// @ts-ignore
import {Link as ScrollLink} from 'react-scroll';

// Images
import xIcon from "../../assets/X Icon.png";


export const Footer: React.FC = () => {

    return (
        <footer id={'footer'} className={'footer'}>
            <div className={'wrapper'}>
                <div className={'flex flex-align-center flex-gap-lg'}>
                    <a target="_blank"  rel="noopener noreferrer" href={'https://twitter.com/jc_louw'}>
                        <img src={xIcon} alt={'X social icon'} loading={'lazy'}/>
                    </a>
                    <span>DFM Global (Pty) Ltd (Registration #2020/917611/07) is an authorised financial services provider (FSP #51494).</span>
                </div>
                <div className={'flex flex-align-center flex-gap-md'}>
                    <span>Legal:</span>
                    <button>
                        <a target="_blank"  rel="noopener noreferrer" href={'https://dfm.co.za/pdf/DFM Protection of Information and Confidentiality Policy.pdf'}>Download</a>
                    </button>
                </div>
            </div>
        </footer>
    );
};