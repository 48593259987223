import React, {useEffect, useRef, useState} from 'react';
import './App.scss';
import './Utils.scss';
import {Header} from "./shared/header/Header";
import {Footer} from "./shared/footer/Footer";
import {Home} from "./pages/home/Home";
import {About} from "./pages/about/About";
import {Insights} from "./pages/insights/Insights";
import {Contact} from "./pages/contact/Contact";

function App() {
    // Ref
    const mainRef = useRef<any>(null);

    // States
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <div className={`App${modalOpen ? ' overflow-hidden' : ''}`}>
            <Header/>

            <main ref={mainRef}>
                <Home/>
                <About/>
                <Insights onClose={(o) => setModalOpen(o)}/>
                <Contact/>
            </main>

            <Footer/>
        </div>
    );
}

export default App;
