// Created By: Chris
// Created On: 2023/06/17

// React
import React, {useEffect, useRef} from 'react';
import './Home.scss';

// Images
import lowCost from '../../assets/Low Cost Icon.png';
import moreTime from '../../assets/More Time Icon.png';
import portfolio from '../../assets/Portfolio Comparison Icon.png';
import financial from '../../assets/Financial Help Icon.png';

// GSAP
import {gsap} from 'gsap';

// Framer
import {motion, useScroll, useTransform} from 'framer-motion';

// Constants
const animationDuration = 0.8;

export const Home: React.FC = () => {
    // Constants
    type tCard = {
        img: string,
        title: string,
        description: any,
    }
    const cards: tCard[] = [
        {
            img: lowCost,
            title: 'Support',
            description: <span>DFM<span className={'line'}> | </span>Global reduces the admin pressure on advisors and streamlines daily operations</span>,
        },
        {
            img: moreTime,
            title: 'Expertise',
            description: 'Direct access to industry experts to bolster advice and product quality',
        },
        {
            img: portfolio,
            title: 'Partnership',
            description: 'Align with a team of experts sharing your vision for business growth',
        },
        {
            img: financial,
            title: 'Client focus',
            description: 'A proven track record of creating low-cost, simplified products with client needs front of mind',
        },
    ];

    // Framer
    const {scrollYProgress} = useScroll();
    const cardTransformUp = useTransform(scrollYProgress, [0, 0.5], ['0', '-500px']);
    const h1Variant = {
        initial: {
            borderColor: 'transparent',
            height: 0
        },
        animate: {
            borderColor: 'var(--clr-primary)',
            height: '100%',
            transition: {
                duration: animationDuration,
            }
        },
    };

    // Refs
    const wrapperRef = useRef(null);

    // Effects
    useEffect(() => {
        gsap.from(wrapperRef.current, {
            yPercent: 20
        });
        gsap.to(wrapperRef.current, {
            yPercent: 0,
            duration: animationDuration,
        });
    }, []);

    const card = (props: tCard): React.ReactElement => {
        return (
            <motion.div
                style={{
                    translateY: cardTransformUp
                }}
                className={'card'}
                key={props.title}
            >
                <img src={props.img} alt={props.title}/>
                <span className={'title'}>{props.title}</span>
                <span className={'description'}>{props.description}</span>
            </motion.div>
        )
    }

    return (
        <>
            <section id={'home'} className={'home'}>
                <div className={'home__wrapper'}>
                    <div></div>
                    <div className={'content'}>
                        <h1 style={{whiteSpace: 'nowrap'}}>
                            <span className={'inner-header'}>Choose&nbsp;</span>
                            DFM
                            <motion.div
                          variants={h1Variant}
                          initial={'initial'}
                          whileInView={'animate'}
                          viewport={{once: true}}
                        />
                            <span className={'inner-header'}>Global</span>
                        </h1>
                        <span className={'sub-header'}>As your dedicated investment partner</span>
                        <span className={'text'}>
                            At DFM, we understand that managing wealth and making informed investment decisions can be
                            complex and time-consuming. That&#39;s where we come in. Our dedicated team of experienced
                            professionals, known as Discretionary Fund Managers, is here to simplify your financial journey and
                            empower you to achieve your unique financial goals.
                        </span>
                    </div>
                </div>
            </section>
            <div id={'home-cards'} className={'cards__wrapper'} ref={wrapperRef}>
                {cards.map((c) => card(c))}
            </div>
        </>
    );
};